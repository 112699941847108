document.addEventListener("turbo:load", () => {

  $("#graph-range-menu .dropdown-item").click(function(){
    // $('#members-chart').css('opacity','.4');
    $('#spinner').show();
  });

  $(document).on('shown.bs.modal', '.modal', function () {
    $(this).find('[autofocus]').focus();
  });

})
